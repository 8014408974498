import { BandyerEnum } from 'bandyersdkcommon';
import Message from './message';
import Logger from '../../../logger';
import BandyerChat from '../client';
import {
    channelIsTyping
} from '../../../store/actions/dispatcher';
import store from '../../../store/store';

const events = require('events');

class Channel extends events {
    constructor() {
        super();
        this._L = Logger.scope('BandyerChat - Channel');
        this._channelId = '';
        this._type = 'single';
        this._uniqueName = '';
        this._friendlyName = '';
        this._participants = [];
        this._messages = new Map();
        this._isTyping = false;
        this._remoteTyping = new Map();
        this._dateCreated = null;
        this._status = BandyerEnum.UserStatus.OFFLINE;
        this._lastMessage = null;
        this._lastReadUserMessage = {};
        this._unreadMessages = 0;
        this._bandyerChannel = null;
        this._client = BandyerChat.getInstance();
    }

    async init(channelData) {
        this._bandyerChannel = channelData;
        try {
            const participants = [];
            const participantsPromises = [];
            channelData.participants.forEach((participant) => {
                const m = participant.userId;
                if (m !== this._client.localUserAlias) {
                    const participantPromise = new Promise(async(resolve) => {
                        const userObj = await this._client._bandyerCommunicationCenter.getUserStatus(m);
                        if (userObj.status !== BandyerEnum.UserStatus.OFFLINE) {
                            this._status = BandyerEnum.UserStatus.ONLINE;
                        }
                        if (userObj.user) {
                            userObj.user.formattedName = userObj.user.userAlias;
                            participants.push(userObj.user);
                        } else {
                            participants.push({ userAlias: m, formattedName: m });
                        }
                        resolve();
                    });
                    participantsPromises.push(participantPromise);
                }
            });
            await Promise.all(participantsPromises);
            if (!participants.length) {
                return Promise.reject(new Error('noParticipants'));
            }
            this._channelId = channelData.channelId;
            this._uniqueName = channelData.uniqueName;
            this._friendlyName = channelData.friendlyName;
            this._participants = participants;
            this._dateCreated = channelData.createdAt;
            if (channelData.lastMessage) {
                this._lastMessage = new Message(channelData.lastMessage);
                this._messages.set(this._lastMessage.messageId, this._lastMessage);
            }
            this._unreadMessages = await channelData.getUnconsumedMessageCount();
            this._lastReadUserMessage = channelData.lastReadUserMessage;
            return this;
        } catch (err) {
            this._L.debug('buildChannelStructure - catch', err);
            throw err;
        }
    }

    async getUnconsumedMessageCount() {
        this.unreadMessages = await this._bandyerChannel.getUnconsumedMessageCount();
        return this.unreadMessages;
    }

    async setAllMessagesConsumed() {
        await this._bandyerChannel.setAllMessagesConsumed();
        this.unreadMessages = 0;
    }

    async getMessages(messagesToFetch, anchor = null, direction = 'backwards') {
        let anchorId;
        if (!anchor && this._lastMessage) {
            anchorId = this._lastMessage.messageId;
        } else {
            anchorId = anchor;
        }
        const messagesData = await this._bandyerChannel.getMessages(messagesToFetch, anchorId, direction);
        const toReturn = [];
        messagesData.messages.forEach((messageData) => {
            const messageToAdd = new Message(messageData);
            this.messages.set(messageToAdd.messageId, messageToAdd);
            toReturn.push(messageToAdd);
        });
        return { messages: toReturn, anchor: messagesData.anchor };
    }

    async sendMessage(message) {
        this._L.debug('[sendMessage]', this.uniqueName, message);
        try {
            const messageReturn = await this._bandyerChannel.sendMessage(message);
            this._L.debug('[sendMessage]', messageReturn);
            return new Message(messageReturn);
        } catch (e) {
            this._L.error(e);
            throw e;
        }
    }

    addMessage(message) {
        if (!this.messages.has(message.messageId)) {
            this.emit('messageAdded', message);
        }
        this.messages.set(message.messageId, message);
    }

    handleTyping(userId) {
        let timeout = this._remoteTyping.get(userId);
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            channelIsTyping(this.uniqueName, false);
            this._remoteTyping.delete(userId);
        }, 3000);
        this._remoteTyping.set(userId, timeout);
        channelIsTyping(this.uniqueName, true);
    }

    disableTyping(userId) {
        channelIsTyping(this.uniqueName, false);
        this._remoteTyping.delete(userId);
    }

    typing() {
        this._bandyerChannel.typing();
    }

    toObj() {
        return {
            channelId: this._channelId,
            uniqueName: this._uniqueName,
            friendlyName: this._friendlyName,
            type: this._type,
            participants: this._participants,
            unreadMessages: this._unreadMessages,
            messages: Array.from(this._messages.values()),
            isTyping: this._isTyping,
            dateCreated: this._dateCreated,
            status: this._status,
            lastMessage: this._lastMessage ? this._lastMessage.toObj() : {},
            lastReadUserMessage: this._lastReadUserMessage
        };
    }


    get channelId() {
        return this._channelId;
    }

    set channelId(value) {
        this._channelId = value;
    }

    get type() {
        return this._type;
    }

    set type(value) {
        this._type = value;
    }

    get uniqueName() {
        return this._uniqueName;
    }

    set uniqueName(value) {
        this._uniqueName = value;
    }

    get friendlyName() {
        return this._friendlyName;
    }

    set friendlyName(value) {
        this._friendlyName = value;
    }

    get participants() {
        return this._participants;
    }

    set participants(value) {
        this._participants = value;
    }

    get messages() {
        return this._messages;
    }

    set messages(value) {
        this._messages = value;
    }

    get isTyping() {
        return this._isTyping;
    }

    set isTyping(value) {
        this._isTyping = value;
    }

    get dateCreated() {
        return this._dateCreated;
    }

    set dateCreated(value) {
        this._dateCreated = value;
    }

    get status() {
        return this._status;
    }

    set status(value) {
        this._status = value;
    }

    get lastMessage() {
        return this._lastMessage;
    }

    set lastMessage(value) {
        this._lastMessage = value;
    }

    get unreadMessages() {
        return this._unreadMessages;
    }

    set unreadMessages(value) {
        this._unreadMessages = value;
    }

    get lastReadUserMessage() {
        return this._lastReadUserMessage;
    }

    set lastReadUserMessage(value) {
        this._lastReadUserMessage = value;
    }

    get client() {
        return this._client;
    }

    set client(value) {
        this._client = value;
    }

    get BandyerChannel() {
        return this._bandyerChannel;
    }
}

export default Channel;
