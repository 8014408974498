
import bandyerChat from './bandyerChat';
import Logger from '../logger';
import SwitchboardService from './switchboardService';
import call from './call';
import { destroyStore } from '../store/actions/dispatcher';

/**
 * @type {BandyerSDK}
 */
let instance = null;


export default class BandyerSDK {
    constructor({ region, environment, appId, chat }) {
        if (instance) {
            return instance;
        }
        this._userId = null;
        this._region = region;
        this._environment = environment;
        this._services = {};
        this._services.switchboard = new SwitchboardService(region, environment, appId);
        this._services.call = call.initialize(this._services.switchboard.communicationCenter);
        this._services.chat = new bandyerChat(this._services.switchboard.communicationCenter, this._region, this._environment);
        this._haveChat = chat;
        instance = this;
    }

    static getInstance() {
        return instance;
    }

    static destroy() {
        SwitchboardService.destroy();
        bandyerChat.destroy();
        call.destroy();
        destroyStore();
        instance = null;
    }

    connect = async(userId, accessToken) => {
        this._userId = userId;
        const localUser = await this._connectSwitchboardService(userId, accessToken);
        if (this._haveChat) {
            await this._connectChatService(userId);
        }
        return localUser;
    }

    disconnect = async() => {
        if (this._services.chat) {
            await this._services.chat.disconnect();
        }
        if (this._services.switchboard.onGoingCall && this._services.call) {
            this._services.call.disconnectCall(this._services.switchboard.onGoingCall.callAlias);
        }

        await this._services.switchboard.disconnect();
    }

    updateAccessToken = async accessToken => this._services.switchboard.updateAccessToken(accessToken)

    async _connectSwitchboardService(userId, accessToken) {
        try {
            return this._services.switchboard.connect(userId, accessToken);
        } catch (e) {
            Logger.error('Unable to initialize switchboard', e);
            throw e;
        }
    }

    async _connectChatService(userId) {
        try {
            const { accessToken, expiresAt } = await this._services.switchboard.getChatToken();
            return this._services.chat.connect(userId, accessToken, expiresAt);
        } catch (e) {
            Logger.error('Unable to initialize the bandyer-chat', e);
            throw e;
        }
    }


    get chat() {
        return this._services.chat;
    }

    get switchboard() {
        return this._services.switchboard;
    }

    get call() {
        return this._services.call;
    }

    get userId() {
        return this._userId;
    }

    get environment() {
        return this._environment;
    }
}
