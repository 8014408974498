import ReactDOM from 'react-dom';
import store from '../../store/store';
import {
    connect,
    addChannel,
    closeWidget,
    createCall,
    hideWidget,
    joinCallURL,
    openWidget,
    removeChannel,
    showWidget,
    toggleWidget, updateAccessToken, changeViewToChannels, disconnectSDK, getOnlineUsers
} from '../../store/actions/dispatcher';
import { BandyerChatWidgetNotAuthenticated, ExposeError } from '../../helpers/errorHandler';
import Style from '../../components/widget/style.scss';
import {
    BANDYER_CHAT_CONTAINER,
    ON_CALL_INCOMING
} from '../../constants';
import { buildChannelUniqueName } from '../../helpers/utils';
import ChannelView from './entities/ChannelView';
import CallView from './entities/CallView';
import BandyerSDK from '../index';

const events = require('events');


export default class Client extends events {
    constructor(region, environment, appId) {
        super();
        this._region = region;
        this._environment = environment;
        this._appId = appId;
        this._userId = null;
        this._channelsView = new Map();
        this._callView = null;
        const { switchboard, chat } = BandyerSDK.getInstance();


        switchboard.on('call_status_changed', ({ status }) => {
            this.emit('call:status:changed', { call: this.callView.call, status });
            this.callView.call.emit('status:changed', { status });
            if (status === 'ended') {
                this._callView = null;
                BandyerSDK.getInstance().call.off('room:recording:started', () => {
                    this.callView.call._recordingState = 'started';
                    this.callView.call.emit('recording:state:changed', { recordingState: 'started' });
                });
                BandyerSDK.getInstance().call.off('room:recording:stopped', () => {
                    this.callView.call._recordingState = 'stopped';
                    this.callView.call.emit('recording:state:changed', { recordingState: 'stopped' });
                });
            }
        });
        switchboard.on('access_token_is_about_to_expire', (data) => {
            this.emit('client:access_token:is_about_to_expire', data);
        });
        switchboard.on('access_token_expired', async(data) => {
            await BandyerSDK.getInstance().disconnect();
            this.emit('client:access_token:expired', data);
        });
        switchboard.on('user_force_disconnect', async(data) => {
            this.emit('client:force_disconnect', data);
        });

        switchboard.on('user_status_update', data => this.emit('user:status:changed', data));

        switchboard.on(ON_CALL_INCOMING, (internalCall) => {
            this._callView = new CallView(internalCall);
            this.emit('call:incoming', { call: this._callView.call });
        });

        // if the call is performed from the UI, this event allows to 'push' the object in the external client in order to have the same capability of a programmatic call
        switchboard.on('call_created', (internalCall) => {
            if (!this._callView) {
                this._callView = new CallView(internalCall);
            }
            BandyerSDK.getInstance().call.on('room:recording:started', () => {
                this.callView.call._recordingState = 'started';
                this.callView.call.emit('recording:state:changed', { recordingState: 'started' });
            });
            BandyerSDK.getInstance().call.on('room:recording:stopped', () => {
                this.callView.call._recordingState = 'stopped';
                this.callView.call.emit('recording:state:changed', { recordingState: 'stopped' });
            });
        });

        if (BandyerSDK.getInstance()._haveChat) {
            chat.on('message_sent', ({ channelUniqueName, messageId }) => {
                const participant = channelUniqueName.split('#').find(user => user !== this.userId);
                const { channel } = this._channelsView.get(participant);
                const message = channel._messages.get(messageId);
                this.emit('channel:message:sent', { message });
            });
            chat.on('message_received', ({ channelUniqueName, messageId }) => {
                const participant = channelUniqueName.split('#').find(user => user !== this.userId);
                const { channel } = this._channelsView.get(participant);
                const message = channel._messages.get(messageId);
                this.emit('channel:message:received', { message });
            });

            chat.on('channel:added', (channel) => {
                const channelView = new ChannelView(channel.uniqueName);
                this._channelsView.set(channelView.channel.participants[0], channelView);
                this.emit('channel:added', { channel: channelView.channel });
            });

            chat.on('channel:removed', (channel) => {
                const { channelId, participants } = channel;
                this._channelsView.delete(participants[0]);
                this.emit('channel:removed', { channelId, participants });
            });
        }
    }


    connect = async(userId, accessToken) => {
        if (store.getState().behavior.get('widgetReady')) {
            throw new ExposeError({ code: 'connect_error', message: 'Client already connected' });
        }
        this._userId = userId;
        return connect(userId, accessToken);
    }

    disconnect = async() => {
        try {
            await disconnectSDK();
            const node = document.getElementById(Style[BANDYER_CHAT_CONTAINER]);
            if (node) {
                ReactDOM.unmountComponentAtNode(node);
            }
        } catch (e) {
            throw new ExposeError({ code: 'disconnect_error', message: 'Fail to logout, perform force close' });
        }
    }


    getChannelView(user) {
        if (!user || (user && typeof user !== 'string')) {
            throw new ExposeError({ code: 'validation_error', message: 'User must be a valid string' });
        }
        if (this._channelsView.has(user)) {
            return this._channelsView.get(user);
        }
        const channelUniqueName = buildChannelUniqueName([this._userId, user]);
        const channelView = new ChannelView(channelUniqueName);
        this._channelsView.set(user, channelView);
        return channelView;
    }


    selectChannelsView = () => {
        changeViewToChannels();
    }


    addChannel = async(user) => {
        if (!user || (user && typeof user !== 'string')) {
            throw new ExposeError({ code: 'validation_error', message: 'User must be a valid string' });
        }
        if (!store.getState().behavior.get('chat')) {
            throw new ExposeError({ code: 'validation_error', message: 'Chat module not initialized' });
        }
        if (store.getState().behavior.get('widgetReady')) {
            const channel = await addChannel(user);
            const channelView = new ChannelView(channel.uniqueName);
            this._channelsView.set(channelView.channel.participants[0], channelView);
            return channelView;
        }
        throw new BandyerChatWidgetNotAuthenticated();
    }

    removeChannel = (user) => {
        if (!user || (user && typeof user !== 'string')) {
            throw new ExposeError({ code: 'validation_error', message: 'User must be a valid string' });
        }
        if (!store.getState().behavior.get('chat')) {
            throw new ExposeError({ code: 'validation_error', message: 'Chat module not initialized' });
        }
        if (store.getState().behavior.get('widgetReady')) {
            return removeChannel(user);
        }
        throw new BandyerChatWidgetNotAuthenticated();
    }

    getOnlineUsers = () => {
        if (store.getState().behavior.get('widgetReady')) {
            return getOnlineUsers();
        }
        throw new BandyerChatWidgetNotAuthenticated();
    }

    showWidget= () => {
        if (store.getState().behavior.get('widgetReady')) {
            showWidget();
            return true;
        }
        throw new BandyerChatWidgetNotAuthenticated();
    }

    hideWidget = () => {
        if (store.getState().behavior.get('widgetReady')) {
            hideWidget();
            return true;
        }
        throw new BandyerChatWidgetNotAuthenticated();
    }

    openWidget= () => {
        if (store.getState().behavior.get('widgetReady')) {
            openWidget();
            return true;
        }
        throw new BandyerChatWidgetNotAuthenticated();
    }

    toggleWidget = () => {
        if (store.getState().behavior.get('widgetReady')) {
            toggleWidget();
            return true;
        }
        throw new BandyerChatWidgetNotAuthenticated();
    }

    closeWidget = () => {
        if (store.getState().behavior.get('widgetReady')) {
            closeWidget();
            return true;
        }
        throw new BandyerChatWidgetNotAuthenticated();
    }

    createCall = async(participants, options = {}) => {
        if (!participants || (participants && !Array.isArray(participants))) {
            throw new ExposeError({ code: 'validation_error', message: 'Participants must be an array of string(userId)' });
        }
        if (participants && participants.length > 1) {
            throw new ExposeError({ code: 'validation_error', message: 'Participants array must contain at most one user' });
        }
        if (!options || (options && typeof options !== 'object')) {
            throw new ExposeError({ code: 'validation_error', message: 'Options must be an object' });
        }
        if (!store.getState().behavior.get('widgetReady')) {
            throw new BandyerChatWidgetNotAuthenticated();
        }
        if (!this._callView) {
            const internalCall = await createCall(participants, options);
            this._callView = new CallView(internalCall);
            return this._callView;
        }
        throw new ExposeError({ code: 'another_call_in_progress', message: 'Another call in progress' });
    }

    joinCallURL = async(url) => {
        if (!url || (url && typeof url !== 'string')) {
            throw new ExposeError({ code: 'validation_error', message: 'Url must be a valid string' });
        }
        if (!store.getState().behavior.get('widgetReady')) {
            throw new BandyerChatWidgetNotAuthenticated();
        }

        if (!this._callView) {
            const internalCall = await joinCallURL(url);
            this._callView = new CallView(internalCall);
            return this._callView;
        }
        // eslint-disable-next-line prefer-promise-reject-errors
        throw new ExposeError({ code: 'another_call_in_progress', message: 'Another call in progress' });
    }

    updateAccessToken = (accessToken) => {
        if (accessToken && typeof accessToken !== 'string') {
            throw new ExposeError({ code: 'validation_error', message: 'Invalid accessToken, the accessToken must be a string' });
        }
        return updateAccessToken(accessToken);
    }

    get channelsView() {
        return this._channelsView;
    }

    get callView() {
        return this._callView;
    }

    get region() {
        return this._region;
    }

    get environment() {
        return this._environment;
    }

    get appId() {
        return this._appId;
    }

    get userId() {
        return this._userId;
    }
}
