import React from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import Loader from 'react-loaders';
import { CallStatus } from 'bandyersdkcommon/dist/src/constants/enum';
import ReactDOM from 'react-dom';
import Style from './components/channels/style.scss';
import ErrorBoundary from '../../../widgetErrorBoundary';
import ChatLoader from './components/chatLoader';
import Call from './components/call/CallContainer';
import CallWindow from './components/callWindow';
import Conversation from './components/conversation';
import Channels from './components/channels';
import DialIn from './components/dialIn';
import DialOut from './components/dialOut/DialOutComponent';
import GenericCallError from '../../../errors/genericCallError/GenericaCallErrorContainer';
import IEPlugin from './components/IEPlugin';
import {
    CONVERSATION,
    DIAL_IN,
    DIAL_OUT,
    CALL,
    CHANNELS,
    ERROR,
    IE_PLUGIN,
    CALL_TYPE_AUDIO_VIDEO,
    CALL_WINDOW,
    INACTIVE_USER, FEEDBACK, INACTIVE_USER_TOKEN_EXPIRED, KICKED_VIEW, BANDYER_CHAT_CONTAINER
} from '../../../../constants';
import DisconnectUserView from './components/disconnectUserView';
import FeedBack from './components/call/components/Feedback';
import { changeViewToChannels, disconnectSDK } from '../../../../store/actions/dispatcher';
import WidgetStyle from '../../style.scss';

function getCurrentParticipant(props) {
    if (props.channels.size) {
        const selectedChannel = props.channels.get(
            props.channels.findIndex(item => item.get('uniqueName') === props.selectedChannel)
        );
        if (selectedChannel && selectedChannel.has('participants')) {
            const participant = selectedChannel.get('participants').get(0);
            if (props.usersDetails.has(participant.get('userAlias'))) {
                return props.usersDetails.get(participant.get('userAlias'));
            }
            props.provideDetails([participant]);
            return participant;
        }
    }
    return Map({});
}

async function logoutAndUnmount() {
    await disconnectSDK();
    ReactDOM.unmountComponentAtNode(document.getElementById(WidgetStyle[BANDYER_CHAT_CONTAINER]));
}

function selectComponent(props) {
    const {
        call,
        currentView,
        hangUpCall,
        dialStyle,
        answerTheCall,
        declineTheCall,
        fullScreenMode,
        toggleFullScreen,
        headerStyle,
        allowCamera,
        videoHasAudioTrack,
        videoHasVideoTrack,
        bodyStyle,
        onRemoveChannel,
        downloadPlugin,
        sendMessage,
        senderPlaceHolder,
        profileAvatar,
        onToggleConversation,
        showChat,
        showCloseButton,
        disabledInput,
        onTypingEvent,
        startCreateCall,
        recording,
        selectedChannel,
        messageStyle,
        changeToChannel,
        selectCurrentChannel,
        widgetCallType,
        closeWindowCall,
        focusWindowCall,
        changeView,
        selectChannel,
        addChannel,
        localUser,
        mode,
        communication_center_state,
        chat_socket_state,
        language,
        usersDetails,
        provideDetails,
        channels,
        notification,
        isLoading,
        fetchMessages,
        haveChat,
        closePlugin,
        chat,
        recordingInfo
    } = props;
    switch (currentView) {
        case DIAL_OUT:
            return (
                <DialOut
                    call={call}
                    hangUp={hangUpCall}
                    dialStyle={dialStyle}
                    usersDetails={usersDetails}
                    provideDetails={provideDetails}
                    recordingInfo={recordingInfo}
                />
            );
        case DIAL_IN:
            return (
                <DialIn
                    call={call}
                    answer={answerTheCall}
                    decline={declineTheCall}
                    dialStyle={dialStyle}
                    usersDetails={usersDetails}
                    provideDetails={provideDetails}
                    recordingInfo={recordingInfo}
                />
            );
        case FEEDBACK:
            return (
                <FeedBack />
            );
        case CHANNELS:
            return (
                <Channels
                    headerStyle={headerStyle}
                    bodyStyle={bodyStyle}
                    removeChannel={onRemoveChannel}
                    chat_socket_state={chat_socket_state}
                    language={language}
                    usersDetails={usersDetails}
                    provideDetails={provideDetails}
                    call={call}
                    changeView={changeView}
                    mode={mode}
                    isLoading={isLoading}
                    focusWindowCall={focusWindowCall}
                    haveChat={haveChat}
                />
            );
        case ERROR:
            return <GenericCallError headerStyle={headerStyle} bodyStyle={bodyStyle} />;
        case IE_PLUGIN:
            return (
                <IEPlugin closePlugin={closePlugin} call={call} downloadPlugin={downloadPlugin} bodyStyle={bodyStyle} />
            );
        case CONVERSATION:
            return (
                <Conversation
                    sendMessage={sendMessage}
                    senderPlaceHolder={senderPlaceHolder}
                    profileAvatar={profileAvatar}
                    toggleChat={onToggleConversation}
                    showChat={showChat}
                    showCloseButton={showCloseButton}
                    disabledInput={disabledInput}
                    isTyping={onTypingEvent}
                    createCall={startCreateCall}
                    recording={recording}
                    selectedChannel={selectedChannel}
                    participant={getCurrentParticipant(props)}
                    headerStyle={headerStyle}
                    bodyStyle={bodyStyle}
                    messageStyle={messageStyle}
                    removeChannel={onRemoveChannel}
                    changeToChannel={changeToChannel}
                    selectCurrentChannel={selectCurrentChannel}
                    widgetCallType={widgetCallType}
                    call={call}
                    changeView={changeView}
                    mode={mode}
                    focusWindowCall={focusWindowCall}
                    communication_center_state={communication_center_state}
                    chat_socket_state={chat_socket_state}
                    fetchMessages={fetchMessages}
                    language={language}
                />
            );
        case CALL_WINDOW:
            return (
                <CallWindow
                    headerStyle={headerStyle}
                    callStyle={dialStyle}
                    focusWindowCall={focusWindowCall}
                    closeWindowCall={closeWindowCall}
                    call={call}
                    selectChannel={selectChannel}
                    addChannel={addChannel}
                    localUser={localUser}
                    changeView={changeView}
                    channels={channels}
                    notification={notification}
                    chat={chat}
                />
            );
        case INACTIVE_USER:
            return (
                <DisconnectUserView
                    headerStyle={headerStyle}
                    bodyStyle={bodyStyle}
                    type={INACTIVE_USER}
                    onClick={logoutAndUnmount}
                />

            );
        case KICKED_VIEW:
            return (
                <DisconnectUserView
                    headerStyle={headerStyle}
                    bodyStyle={bodyStyle}
                    type={KICKED_VIEW}
                    onClick={changeViewToChannels}

                />
            );
        case INACTIVE_USER_TOKEN_EXPIRED:
            return (
                <DisconnectUserView
                    headerStyle={headerStyle}
                    bodyStyle={bodyStyle}
                    type={INACTIVE_USER_TOKEN_EXPIRED}
                    onClick={logoutAndUnmount}
                />

            );
        default:
            return null;
    }
}

function MainComponent(props) {
    const { isWidgetReady, showChat, bodyStyle, isLoading, currentView } = props;
    if (!isWidgetReady && currentView !== INACTIVE_USER && currentView !== INACTIVE_USER_TOKEN_EXPIRED) {
        // if the chat is not ready, display only the loader
        return (
            <div className={Style['loader-container']}>
                <div className={Style['loader-position']}>
                    <h2>Loading...</h2>
                    <ChatLoader />
                </div>
            </div>
        );
    }
    const toRender = selectComponent(props);
    return (
        <ErrorBoundary>
            <div
                className={
                    showChat
                        ? Style['conversation-container']
                        : Style['conversation-container'].concat(' ', Style.hidden)
                }
                style={{ color: bodyStyle.get('color') }}
            >
                <ChatLoader isLoading={isLoading} />
                {!props.call.isEmpty() && props.call.get('callStatus') === CallStatus.CONNECTED && (
                    <Call
                        currentView={props.currentView}
                        call={props.call}
                        fullScreenMode={props.fullScreenMode}
                        toggleFullScreen={props.toggleFullScreen}
                        callStyle={props.callStyle}
                        headerStyle={props.headerStyle}
                        allowCamera={props.allowCamera}
                        videoHasAudioTrack={props.videoHasAudioTrack}
                        videoHasVideoTrack={props.videoHasVideoTrack}
                        selectChannel={props.selectChannel}
                        addChannel={props.addChannel}
                        localUser={props.localUser}
                        usersDetails={props.usersDetails}
                    />
                )}
                {toRender}
            </div>
        </ErrorBoundary>
    );
}

MainComponent.propTypes = {
    sendMessage: PropTypes.func,
    onToggleConversation: PropTypes.func,
    showChat: PropTypes.bool,
    senderPlaceHolder: PropTypes.string,
    profileAvatar: PropTypes.string,
    showCloseButton: PropTypes.bool,
    disabledInput: PropTypes.bool,
    selectedChannel: PropTypes.string,
    isLoading: PropTypes.bool,
    isWidgetReady: PropTypes.bool,
    isTyping: PropTypes.func,
    createCall: PropTypes.func,
    recording: PropTypes.string,
    currentView: PropTypes.string,
    channels: PropTypes.instanceOf(List),
    call: PropTypes.instanceOf(Map),
    answerTheCall: PropTypes.func,
    declineTheCall: PropTypes.func,
    hangUpCall: PropTypes.func,
    changeToChannel: PropTypes.func,
    toggleFullScreen: PropTypes.func,
    fullScreenMode: PropTypes.bool,
    downloadPlugin: PropTypes.func,
    focusWindowCall: PropTypes.func,
    closeWindowCall: PropTypes.func,
    localUser: PropTypes.instanceOf(Map),
    headerStyle: PropTypes.instanceOf(Map),
    bodyStyle: PropTypes.instanceOf(Map),
    fontFamily: PropTypes.string,
    messageStyle: PropTypes.instanceOf(Map),
    callStyle: PropTypes.instanceOf(Map),
    dialStyle: PropTypes.instanceOf(Map),
    removeChannel: PropTypes.func,
    allowCamera: PropTypes.bool,
    widgetCallType: PropTypes.string,
    videoHasAudioTrack: PropTypes.bool,
    videoHasVideoTrack: PropTypes.bool,
    changeView: PropTypes.func,
    selectChannel: PropTypes.func,
    addChannel: PropTypes.func,
    communication_center_state: PropTypes.string,
    chat_socket_state: PropTypes.string,
    language: PropTypes.string,
    usersDetails: PropTypes.instanceOf(Map),
    provideDetails: PropTypes.func,
    fetchMessages: PropTypes.func,
    haveChat: PropTypes.bool,
    closePlugin: PropTypes.func,
    chat: PropTypes.bool,
    recordingInfo: PropTypes.exact({
        recordingInProgress: PropTypes.bool,
        recording: PropTypes.string
    })
};

MainComponent.defaultProps = {
    sendMessage: null,
    onToggleConversation: null,
    showChat: true,
    senderPlaceHolder: '',
    profileAvatar: '',
    showCloseButton: true,
    disabledInput: false,
    selectedChannel: '',
    isLoading: false,
    isWidgetReady: false,
    isTyping: null,
    recording: 'none',
    createCall: null,
    currentView: CONVERSATION,
    channels: List([]),
    call: Map({}),
    answerTheCall: null,
    declineTheCall: null,
    hangUpCall: null,
    focusWindowCall: null,
    closeWindowCall: null,
    changeToChannel: null,
    toggleFullScreen: null,
    fullScreenMode: false,
    downloadPlugin: null,
    localUser: Map({}),
    fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
    headerStyle: Map({}),
    bodyStyle: Map({}),
    messageStyle: Map({}),
    callStyle: Map({}),
    dialStyle: Map({}),
    removeChannel: null,
    allowCamera: true,
    widgetCallType: CALL_TYPE_AUDIO_VIDEO,
    videoHasAudioTrack: false,
    videoHasVideoTrack: false,
    changeView: null,
    selectChannel: null,
    addChannel: null,
    communication_center_state: null,
    chat_socket_state: null,
    language: 'en',
    usersDetails: Map({}),
    provideDetails: null,
    fetchMessages: null,
    haveChat: true,
    closePlugin: null,
    chat: false,
    recordingInfo: { recordingInProgress: false, recording: 'none' }
};

export default MainComponent;
