import store from '../store';
import { widgetOperations } from '../../components/widget/redux';
import { socketStateOperations } from '../../components/socketStatus/redux';
import { usersDetailsOperations } from '../../components/usersDetails/redux';
import { callOperations } from '../../components/widget/components/main/components/call/redux';
import { validateCallTypeConfig } from '../../helpers/parseConfig';
import { buildChannelUniqueName, buildChannelListForClient, buildUnreadMessagesForClient } from '../../helpers/utils';
import {
    DIAL_OUT,
    DIAL_IN,
    CALL,
    CONVERSATION,
    CHANNELS,
    PUBLISHER_WEBCAM,
    IE_PLUGIN,
    CALL_WINDOW,
    ERROR, INACTIVE_USER, FEEDBACK, WIDGET_MODE_EMBED, KICKED_VIEW
} from '../../constants';

export function addUserMessage(uniqueName, messageObj) {
    store.dispatch(widgetOperations.addUserMessage(uniqueName, messageObj));
}

export function addResponseMessage(uniqueName, messageObj) {
    store.dispatch(widgetOperations.addResponseMessage(uniqueName, messageObj));
}

export function toggleWidget() {
    store.dispatch(widgetOperations.toggleChat());
}

export function toggleInputDisabled() {
    store.dispatch(widgetOperations.toggleInputDisabled());
}

export function disconnectSDK() {
    return store.dispatch(widgetOperations.shutdown());
}

export function destroyStore() {
    return store.dispatch(widgetOperations.destroy());
}

export function addChannel(user) {
    return store.dispatch(widgetOperations.addChannel(user));
}

export function removeChannel(user) {
    return store.dispatch(widgetOperations.removeChannel(user));
}

export function handleChannelAdded(channelCreated, isNew) {
    if (isNew) {
        store.dispatch(widgetOperations.hideWidget(false));
    }
    return store.dispatch(widgetOperations.fetchedSubscribedChannels(channelCreated));
}

export function handleChannelRemoved(channelRemoved) {
    store.dispatch(widgetOperations.removeChat(channelRemoved));
    return store.dispatch(widgetOperations.removeSubscribedChannel(channelRemoved));
}

export async function handleChanneSelectedAfterRemove(channelUniqueName) {
    const { behavior } = store.getState();
    if (
        behavior.has('selectedChannel')
        && behavior.get('selectedChannel') === channelUniqueName
        && behavior.get('view') === CONVERSATION
    ) {
        await store.dispatch(widgetOperations.changeView(CHANNELS));
        return store.dispatch(widgetOperations.setChannel(''));
    }
    return null;
}

export function updateChannelLastMessage(uniqueName, lastMessage) {
    return store.dispatch(widgetOperations.updateChannelLastMessage(uniqueName, lastMessage));
}

export function updateChannelLastReadUserMessage(uniqueName, lastMessage) {
    return store.dispatch(widgetOperations.updateLastReadUserMessage(uniqueName, lastMessage));
}

export function channelIsTyping(uniqueName, isTyping) {
    return store.dispatch(widgetOperations.channelIsTyping(uniqueName, isTyping));
}

export function emptyMessages() {
    return store.dispatch(widgetOperations.emptyMessages());
}

export function emptyChannels() {
    return store.dispatch(widgetOperations.emptyChannels());
}

export function configureSDK(config) {
    return store.dispatch(widgetOperations.initialize(config));
}

export function connect(userId, accessToken) {
    return store.dispatch(widgetOperations.connect(userId, accessToken));
}

export function updateAccessToken(accessToken) {
    return store.dispatch(widgetOperations.updateAccessToken(accessToken));
}

export function getCallTools() {
    return {
        ...store.getState().behavior.get('callTools'),
        chat: store.getState().behavior.get('chat')
    };
}

export function getVirtualBackgroundSetting() {
    const { enable, type } = store.getState().behavior.get('virtualBackground');
    if (enable) {
        return type;
    }
    return false;
}

export function getUsersDetailsCallPage(callParticipants) {
    const usersDetails = store.getState().usersDetails.get('usersDetails');
    const callPageUsersDetails = [];
    const participantsAlias = callParticipants.map(participant => participant.user.userAlias);
    participantsAlias.forEach((userAlias) => {
        const displayName = usersDetails.has(userAlias) ? usersDetails.get(userAlias).get('formattedName') : userAlias;
        callPageUsersDetails.push({ alias: userAlias, displayName });
    });
    return callPageUsersDetails;
}

export function changeViewToDialOut() {
    return store.dispatch(widgetOperations.changeView(DIAL_OUT));
}

export function changeViewToDialIn() {
    return store.dispatch(widgetOperations.changeView(DIAL_IN));
}

export function changeViewToChannels() {
    return store.dispatch(widgetOperations.changeView(CHANNELS));
}

export function changeViewToChat() {
    return store.dispatch(widgetOperations.changeView(CONVERSATION));
}

export function changeViewToCallWindow() {
    return store.dispatch(widgetOperations.changeView(CALL_WINDOW));
}

export function changeViewToInactiveUser(view) {
    store.dispatch(widgetOperations.setWidgetReady(false));
    return store.dispatch(widgetOperations.changeView(view));
}

export function changeViewToKicked() {
    store.dispatch(callOperations.updateCall({}));
    store.dispatch(callOperations.setFullScreenMode(false));
    store.dispatch(callOperations.publishScreen(false));
    store.dispatch(callOperations.resetAudio());
    store.dispatch(callOperations.resetVideo());
    return store.dispatch(widgetOperations.changeView(KICKED_VIEW));
}

export function resetToChannelView() {
    return store.dispatch(widgetOperations.resetToChannelView());
}

export function getWidgetMode() {
    const { behavior } = store.getState();
    return behavior.get('mode');
}

export function changeViewToCall() {
    return store.dispatch(widgetOperations.changeView(CALL));
}

export function changeViewToIEPlugin() {
    const { behavior } = store.getState();
    if (behavior.get('view') !== IE_PLUGIN) {
        return store.dispatch(widgetOperations.changeView(IE_PLUGIN));
    }
    return null;
}

export function handleStoppedCall(call) {
    return store.dispatch(callOperations.handleStoppedCall(call));
}

export function hangUpCall(callAlias) {
    return store.dispatch(callOperations.hangUpTheCall(callAlias));
}

export function disconnectCall(callAlias) {
    return store.dispatch(callOperations.disconnectTheCall(callAlias));
}

export function answerCall(callAlias) {
    return store.dispatch(callOperations.answerTheCall(callAlias));
}

export function declineCall(callAlias) {
    return store.dispatch(callOperations.declineTheCall(callAlias));
}

export function updateCall(call) {
    return store.dispatch(callOperations.updateCall(call));
}

export function changeMainVideo(mainVideo = PUBLISHER_WEBCAM) {
    return store.dispatch(callOperations.changeMainVideo(mainVideo));
}

export function toggleAudio() {
    return store.dispatch(callOperations.togglePublisherAudio());
}

export function muteAudio(muted) {
    return store.dispatch(callOperations.toggleAudio(muted));
}

export function showMuteByAdmin(show) {
    return store.dispatch(callOperations.showMuteByAdmin(show));
}


export function toggleVideo() {
    return store.dispatch(callOperations.togglePublisherVideo());
}

export function handleErrorGum() {
    return store.dispatch(callOperations.errorGum());
}

export function handleMultipleSocket() {
    return store.dispatch(callOperations.multipleSocketError());
}

export function addFileToDownload(file) {
    return store.dispatch(callOperations.addFileToDownload(file));
}

export function handleErrorCallSocket() {
    return store.dispatch(callOperations.errorCallSocket());
}
export function setReconnectingCall(value) {
    return store.dispatch(callOperations.setReconnectingCall(value));
}

export function setCameraPermissionDenied(value) {
    return store.dispatch(callOperations.setCameraPermissionDenied(value));
}

export function setMicrophonePermissionDenied(value) {
    return store.dispatch(callOperations.setMicrophonePermissionDenied(value));
}

export function setMediaStream(mediaStream) {
    return store.dispatch(callOperations.setMediaStream(mediaStream));
}

export function updateRecordingInfo(recordingInfo) {
    return store.dispatch(callOperations.updateRecordingInfo(recordingInfo));
}

export function updateRecordingInProgress(recordingInProgress) {
    return store.dispatch(callOperations.updateRecordingInProgress(recordingInProgress));
}

export function setIsAdmin(isAdmin) {
    return store.dispatch(callOperations.setIsAdmin(isAdmin));
}

export function setError(error) {
    return store.dispatch(callOperations.setError(error));
}

export function setVerification(verification) {
    return store.dispatch(callOperations.setVerification(verification));
}

export function resetFile() {
    return store.dispatch(callOperations.resetFile());
}

export function setVirtualBackgrounds(virtualBackgrounds) {
    return store.dispatch(callOperations.setVirtualBackgrounds(virtualBackgrounds));
}

export function setVirtualBackgroundConfig(virtualBackgroundConfig) {
    return store.dispatch(widgetOperations.setVirtualBackgroundConfig(virtualBackgroundConfig));
}

export function showWidget() {
    store.dispatch(widgetOperations.hideWidget(false));
}

export function hideWidget() {
    store.dispatch(widgetOperations.hideWidget(true));
}

export function openWidget() {
    store.dispatch(widgetOperations.expandWidget());
}

export function closeWidget() {
    store.dispatch(widgetOperations.closeWidget());
}

export function setExtensionUrl(extensionUrl) {
    store.dispatch(widgetOperations.setExtensionUrl(extensionUrl));
}

export function setUserStatusInChannel(uniqueName, status) {
    store.dispatch(widgetOperations.setUserStatusInChannel(uniqueName, status));
}

/** ************** DISPATCHER FOR ACTIVITIES ************************* */

export function updateMessageSent(message) {
    store.dispatch(widgetOperations.updateLastMessageSent(message));
}

export function updateMessageReceived(message) {
    store.dispatch(widgetOperations.updateLastMessageReceived(message));
}

export function getLastMessageReceived() {
    const { activity } = store.getState();
    return activity.get('lastMessageReceived');
}

export function getLastMessageSent() {
    const { activity } = store.getState();
    return activity.get('lastMessageSent');
}

export function setUnreadMessagesForChannel(uniqueName, unreadMessages) {
    store.dispatch(widgetOperations.setUnreadMessagesForChannel(uniqueName, unreadMessages));
}

export function getUnreadMessages() {
    const { activity } = store.getState();
    const unreadMessages = activity.get('unreadMessages').toJS();
    return buildUnreadMessagesForClient(unreadMessages);
}

/**
 * Used for activity reducer
 * @param uniqueName
 * @param unreadMessages
 */
export function updateUnreadMessagesForChannel(uniqueName, unreadMessages) {
    store.dispatch(widgetOperations.updateUnreadMessagesForChannel(uniqueName, unreadMessages));
}

export function getUserStatus(userAlias) {
    return store.dispatch(widgetOperations.getUserStatus(userAlias));
}

export function getUsersStatusList() {
    return store.dispatch(widgetOperations.getUsersStatusList());
}
export function setHaveChat(haveChat) {
    store.dispatch(widgetOperations.setHaveChat(haveChat));
}

/** ************** DISPATCHER WITH LOGIC FOR CALL METHODS ************************* */

export function createCall(participants, options) {
    const { behavior } = store.getState();
    const parsedOptions = {
        recording: 'recording' in options ? options.recording : behavior.get('recording'),
        isAdmin: 'isAdmin' in options ? !!options.isAdmin : behavior.get('isAdmin'),
        callType: options.callType ? validateCallTypeConfig(options.callType) : behavior.get('widgetCallType')
    };
    return store.dispatch(callOperations.createCall(participants, parsedOptions));
}

export function joinCallURL(url) {
    store.dispatch(widgetOperations.hideWidget(false));
    store.dispatch(widgetOperations.expandWidget());
    return store.dispatch(callOperations.joinCallURL(url));
}

export function handleIncomingCall(call) {
    const { behavior } = store.getState();
    const allowedViews = [CONVERSATION, CHANNELS, ERROR, FEEDBACK, KICKED_VIEW];
    if (allowedViews.includes(behavior.get('view'))) {
        return store.dispatch(callOperations.handleIncomingCall(call));
    }
    throw new Error('The user is busy on another call');
}

export function handleDeclinedCall() {
    const { behavior } = store.getState();
    if (behavior.get('view') === DIAL_OUT || behavior.get('view') === DIAL_IN) {
        return store.dispatch(callOperations.handleDeclinedCall());
    }
    throw new Error('The user is not in dial state');
}
export function resetCall() {
    return store.dispatch(callOperations.handleDeclinedCall());
}

export function selectChat(channelUniqueName) {
    const { behavior } = store.getState();
    if (behavior.get('view') === CHANNELS || behavior.get('view') === CONVERSATION || behavior.get('view') === CALL || behavior.get('view') === CALL_WINDOW) {
        return store.dispatch(
            widgetOperations.selectChannel(channelUniqueName)
        );
    }
    throw new Error('This action is not allowed in this state');
}

export function selectCall() {
    const { behavior } = store.getState();
    if (!behavior.get('call').isEmpty()) {
        if (behavior.get('mode') === WIDGET_MODE_EMBED) {
            return store.dispatch(
                widgetOperations.changeView(CALL)
            );
        }
        return store.dispatch(
            widgetOperations.changeView(CALL_WINDOW)
        );
    }
    throw new Error('This action is not allowed in this state');
}

export function setChannelTextArea(channelUniqueName, message) {
    store.dispatch(widgetOperations.updateChannelTextArea(channelUniqueName, message));
}

export function getChannelTextArea(channelUniqueName) {
    const { channels } = store.getState();
    const channel = channels.find(c => c.get('uniqueName') === channelUniqueName);
    return channel.has('textArea') ? channel.get('textArea') : '';
}

export function setFeedbackInfo(jwt, sessionAlias) {
    store.dispatch(widgetOperations.setFeedbackInfo(jwt, sessionAlias));
}

export function getChatList() {
    const { behavior, channels } = store.getState();
    if (behavior.has('localUser')) {
        return buildChannelListForClient(channels.toJS(), behavior.get('localUser').get('userAlias'));
    }
    throw new Error('This action is not permitted in this state');
}

/** ************** DISPATCHER FOR CALL ************************* */

export function publishWebcam(value) {
    store.dispatch(callOperations.publishWebcam(value));
}

export function publishScreen(value) {
    store.dispatch(callOperations.publishScreen(value));
}

export function publisherHasAudio() {
    store.dispatch(callOperations.publisherHasAudio());
}

export function publisherHasVideo() {
    store.dispatch(callOperations.publisherHasVideo());
}

export function selectAudioDevice(audioDevice) {
    store.dispatch(callOperations.selectAudioDevice(audioDevice));
}

export function selectVideoDevice(videoDevice) {
    store.dispatch(callOperations.selectVideoDevice(videoDevice));
}

export function getEnumerateDevices() {
    store.dispatch(callOperations.getEnumerateDevices());
}

export function setStreamEndedError(value) {
    store.dispatch(callOperations.setStreamEndedError(value));
}

export function setStreamFailedError(value) {
    store.dispatch(callOperations.setStreamFailedError(value));
}

export function setRecordingError(value) {
    store.dispatch(callOperations.setRecordingError(value));
}

export function setRemoteVideoMuted(value) {
    store.dispatch(callOperations.setRemoteVideoMuted(value));
}

export function showRequestPlayPermission(show) {
    store.dispatch(callOperations.showRequestPlayPermission(show));
}

export function pushFailedMediaElement(HTMLMediaElement) {
    store.dispatch(callOperations.setFailedMediaElements(HTMLMediaElement));
}

/** ******************** dispatcher for socket state ****************** */

export function updateCommunicationCenterState(state) {
    store.dispatch(socketStateOperations.setCommunicationCenterState(state));
}
export function updateChatSocketState(state) {
    store.dispatch(socketStateOperations.setChatSocketState(state));
}
export function updateCallSocketState(state) {
    store.dispatch(socketStateOperations.setCallSocketState(state));
}

/** ******************** dispatcher for usersDetail ****************** */

export function setUsersDetails(users) {
    store.dispatch(usersDetailsOperations.updateUsersDetails(users));
}

export function setUsersStatus(onlineUsers) {
    store.dispatch(usersDetailsOperations.setOnlineUsers(onlineUsers));
}

export function updateUserStatus(userAlias, status) {
    store.dispatch(usersDetailsOperations.updateOnlineUsers(userAlias, status));
}

export function getOnlineUsers() {
    const { usersDetails } = store.getState();
    const onlineUsersMap = usersDetails.get('usersStatus');
    const onlineUsers = [];
    onlineUsersMap.forEach((value, key) => {
        if (value === 'online') {
            onlineUsers.push(key);
        }
    });
    return onlineUsers;
}
