import { fromJS } from 'immutable';
import types from './types';
import * as actions from '../../../../../../../store/actions/actionTypes';

function updateCall(call) {
    return {
        type: types.UPDATE_CALL,
        call: fromJS(call)
    };
}

/** ************** DEVICE ****************** */

function publishWebcam(publish) {
    return {
        type: types.PUBLISH_VIDEO,
        publish
    };
}

function publishScreen(publish) {
    return {
        type: types.PUBLISH_SCREEN,
        publish
    };
}

function resetAudio() {
    return {
        type: types.CHANGE_MUTE_AUDIO,
        muted: false
    };
}

function resetVideo() {
    return {
        type: types.CHANGE_MUTE_VIDEO,
        muted: false
    };
}

function changeMainVideo(mainVideo) {
    return {
        type: actions.CHANGE_MAIN_VIDEO,
        mainVideo
    };
}

function toggleAudio(muted) {
    return {
        type: actions.CHANGE_MUTE_AUDIO,
        muted
    };
}

function toggleVideo(muted) {
    return {
        type: actions.CHANGE_MUTE_VIDEO,
        muted
    };
}

function showExtensionAlert(show) {
    return {
        type: actions.SHOW_EXTENSION_ALERT,
        show
    };
}

function setVideoHasAudioTrack(hasAudioTrack) {
    return {
        type: types.VIDEO_HAS_TRACK_AUDIO,
        hasAudioTrack
    };
}

function setVideoHasVideoTrack(hasVideoTrack) {
    return {
        type: types.VIDEO_HAS_TRACK_VIDEO,
        hasVideoTrack
    };
}

/** ************** CALL TOOLS ****************** */

function setFullScreenMode(active) {
    return {
        type: types.SET_FULL_SCREEN_MODE,
        active
    };
}

function enumerateDevices(mediaDevices) {
    return {
        type: types.MEDIADEVICES,
        mediaDevices
    };
}

function selectAudioDevice(audioDevice) {
    return {
        type: types.SELECT_AUDIO_DEVICE,
        audioDevice
    };
}

function selectVideoDevice(videoDevice) {
    return {
        type: types.SELECT_VIDEO_DEVICE,
        videoDevice
    };
}
function setMicrophonePermissionDenied(permission) {
    return {
        type: types.SET_MICROPHONE_PERMISSION_DENIED,
        permission
    };
}

function setCameraPermissionDenied(permission) {
    return {
        type: types.SET_CAMERA_PERMISSION_DENIED,
        permission
    };
}

function setMediaStream(mediaStream) {
    return {
        type: types.SET_MEDIA_STREAM,
        mediaStream
    };
}

function setStreamEndedError(streamEnded) {
    return {
        type: types.SET_STREAM_ENDED,
        streamEnded
    };
}

function setStreamFailedError(streamFailed) {
    return {
        type: types.SET_STREAM_FAILED,
        streamFailed
    };
}

function setRecordingError(recordingError) {
    return {
        type: types.SET_RECORDING_ERROR,
        recordingError
    };
}

function setReconnectingCall(reconnecting) {
    return {
        type: types.SET_RECONNECTING_CALL,
        reconnecting
    };
}

function setError(error) {
    return {
        type: types.SET_ERROR,
        error
    };
}

function setVerification(verification) {
    return {
        type: types.SET_VERIFICATION,
        verification
    };
}

function setRemoteVideoMuted(muted) {
    return {
        type: types.SET_REMOTE_MUTED,
        muted
    };
}

function addFile(newFile) {
    return {
        type: types.SET_FILE_TO_DOWNLOAD,
        newFile
    };
}

function setFileUploaded(newFile) {
    return {
        type: types.SET_FILE_UPLOADED,
        newFile
    };
}

function removeFileUploaded(fileName) {
    return {
        type: types.REMOVE_FILE_UPLOADED,
        fileName
    };
}

function updateDownloadFile(newFile) {
    return {
        type: types.SET_FILE_TO_DOWNLOAD,
        newFile
    };
}

function setFilesDownload(filesToDownload) {
    return {
        type: types.SET_FILES_TO_DOWNLOAD,
        filesToDownload
    };
}

function setFilesUpload(filesUploaded) {
    return {
        type: types.SET_FILES_TO_UPLOAD,
        filesUploaded
    };
}

function updateRecordingInfo(recordingInfo) {
    return {
        type: types.UPDATE_RECORDING_INFO,
        recordingInfo
    };
}

function updateRecordingInProgress(recordingInProgress) {
    return {
        type: types.UPDATE_RECORDING_IN_PROGRESS,
        recordingInProgress
    };
}

function setIsAdmin(isAdmin) {
    return {
        type: types.SET_IS_ADMIN,
        isAdmin
    };
}

function setVirtualBackgrounds(virtualBackgrounds) {
    return {
        type: types.SET_VIRTUAL_BACKGROUNDS,
        virtualBackgrounds
    };
}

function showRequestPlayPermission(show) {
    return {
        type: types.SHOW_REQUEST_PLAY_PERMISSION,
        show
    };
}

function setFailedMediaElements(HTMlMediaElement) {
    return {
        type: types.SET_FAILED_MEDIA_ELEMENT,
        HTMlMediaElement
    };
}

function showMuteByAdmin(show) {
    return {
        type: types.SHOW_MUTE_BY_ADMIN,
        show
    };
}

export default {
    updateCall,
    resetVideo,
    resetAudio,
    publishScreen,
    publishWebcam,
    setFullScreenMode,
    showExtensionAlert,
    toggleAudio,
    toggleVideo,
    changeMainVideo,
    setVideoHasVideoTrack,
    setVideoHasAudioTrack,
    enumerateDevices,
    selectAudioDevice,
    selectVideoDevice,
    setMicrophonePermissionDenied,
    setCameraPermissionDenied,
    setMediaStream,
    setStreamEndedError,
    setStreamFailedError,
    setReconnectingCall,
    setError,
    setVerification,
    setRemoteVideoMuted,
    addFile,
    setFileUploaded,
    updateDownloadFile,
    setFilesDownload,
    setFilesUpload,
    removeFileUploaded,
    updateRecordingInfo,
    updateRecordingInProgress,
    setIsAdmin,
    setVirtualBackgrounds,
    showRequestPlayPermission,
    setFailedMediaElements,
    setRecordingError,
    showMuteByAdmin
};
