import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { fromJS } from 'immutable';
import { VirtualBackgroundService, BrowserUtil } from '@bandyer/web-core-av';
import actions from './actions';
import {
    CHANNELS,
    CONVERSATION,
    BROWSER_NOT_SUPPORTED,
    ERROR, FEEDBACK, BANDYER_CHAT_CONTAINER
} from '../../../constants';
import BandyerSDK from '../../../services';

import {
    ExposeError
} from '../../../helpers/errorHandler';
import {
    validateCallTypeConfig,
    validateModeConfig,
    validateLanguageConfig,
    validateToolsConfig
} from '../../../helpers/parseConfig';
import Logger from '../../../logger';
import { styleOperations } from '../../common/style/redux';
import store from '../../../store/store';
import UserDetailsService from '../../../services/userDetails';
import Style from '../style.scss';
import BandyerChatWidget from '../../bandyerChatWidget/BandyerChatWidgetContainer';
import { buildChannelUniqueName, isBrowserSupported } from '../../../helpers/utils';
import { usersDetailsOperations } from '../../usersDetails/redux';
import Client from '../../../services/exposure/client';
import BandyerChat from '../../../services/bandyerChat';

const {
    addUserMessage,
    toggleChat,
    toggleFullScreen,
    logout,
    setHideWidget,
    wasHide,
    chatInitHasError,
    chatIsLoading,
    setLocalUserInfo,
    setRecordingValue,
    setWidgetReady,
    setChannel,
    setMessages,
    updateUnreadMessagesForChannel,
    changeView,
    addResponseMessage,
    toggleInputDisabled,
    fetchedSubscribedChannels,
    removeSubscribedChannel,
    channelIsTyping,
    updateChannelLastMessage,
    showWidget,
    selectCurrentChannel,
    closeWidget,
    setExtensionUrl,
    updateLastMessageReceived,
    updateLastMessageSent,
    setUnreadMessagesForChannel,
    fetchedSubscribedChannelsNoResults,
    setWidgetCallType,
    setAllowCamera,
    setWidgetMode,
    setLanguage,
    setUserStatusInChannel,
    removeChat,
    setHaveChat,
    wasClose,
    setChatInitialized,
    setVirtualBackgroundConfig,
    setTools,
    setAdmin,
    setFeedbackInfo,
    setFeedbackEnabled,
    setConnectionInfo,
    updateChannelTextArea,
    emptyMessages,
    emptyChannels,
    updateLastReadUserMessage
} = actions;

/** ****************** MESSAGE OPERATIONS ********************** */

const sendMessage = function(uniqueName, message) {
    return () => BandyerSDK.getInstance().chat.sendMessage(uniqueName, message);
};

/** ****************** USER ********************** */

function getUserStatus(userAlias) {
    return async() => {
        const state = store.getState();

        const { channels } = state;

        const channelUniqueName = buildChannelUniqueName([BandyerSDK.getInstance().switchboard.userId, userAlias]);
        const channel = channels.find(item => item.get('uniqueName') === channelUniqueName);
        if (channel) {
            return {
                userAlias,
                status: channel.get('status')
            };
        }
        return BandyerSDK.getInstance().switchboard.getUserStatus(userAlias);
    };
}

function getUsersStatusList() {
    return () => BandyerSDK.getInstance().switchboard.getUsersStatusList();
}

/** ******** CHANNEL OPERATIONS ******************** */


function fetchMessages(uniqueName, anchor, direction = 'backwards') {
    return (dispatch) => {
        // loader
        const timeout = new Promise(resolve => setTimeout(() => {
            resolve();
        }, 2000));
        const fetchPromise = BandyerSDK.getInstance().chat.fetchMessage(uniqueName, anchor, direction);

        return Promise.all([timeout, fetchPromise])
            .then((returnArray) => {
                const messages = returnArray[1].messages.map(message => message.toObj());
                dispatch(setMessages(messages, uniqueName));
                return messages;
            })
            .catch((e) => {
                Logger.warn('[fetchMessage] - error', e);
                throw new ExposeError(e);
            });
    };
}

const selectChannel = function selectChannel(uniqueName) {
    const state = store.getState();

    const { behavior } = state;
    const showChat = behavior.get('showChat');
    let isViewConversation = false;
    return async(dispatch) => {
        dispatch(chatIsLoading(true));
        if (state.messages.has(uniqueName)) {
            dispatch(setChannel(uniqueName));
            dispatch(changeView(CONVERSATION));
            isViewConversation = true;
        }
        try {
            const selectedChannel = await BandyerSDK.getInstance().chat.selectCurrentChannel(uniqueName);
            dispatch(usersDetailsOperations.provideDetails([fromJS(selectedChannel.participants[0])]));
            if (selectedChannel.messages && !state.messages.has(uniqueName)) {
                dispatch(setMessages(selectedChannel.messages, selectedChannel.uniqueName));
            }

            if (isViewConversation === false) {
                dispatch(setChannel(uniqueName));
                dispatch(changeView(CONVERSATION));
            }
            if (showChat) {
                dispatch(updateUnreadMessagesForChannel(uniqueName, 0));
                dispatch(setUnreadMessagesForChannel(uniqueName, 0));
            }
            dispatch(chatIsLoading(false));
            return Promise.resolve();
        } catch (err) {
            Logger.error('err', err);
            dispatch(chatIsLoading(false));
            return Promise.reject(err);
        }
    };
};


function expandWidget() {
    return (dispatch) => {
        const state = store.getState();
        dispatch(wasClose(!state.behavior.get('showChat')));
        dispatch(showWidget());
    };
}

function hideWidget(hide) {
    return (dispatch) => {
        const state = store.getState();
        dispatch(wasHide(state.behavior.get('hideWidget')));
        dispatch(setHideWidget(hide));
    };
}

function addChannel(user, options) {
    return async(dispatch) => {
        dispatch(chatIsLoading(true));

        return new Promise(async(resolve, reject) => {
            try {
                const participants = [user, BandyerSDK.getInstance().chat.localUserAlias];
                const uniqueName = buildChannelUniqueName(participants);
                const channel = BandyerSDK.getInstance().chat.getChannelByUniqueName(uniqueName);
                if (channel) {
                    return resolve(channel);
                }

                const resolveFunction = (data) => {
                    if (uniqueName === data.uniqueName) {
                        BandyerSDK.getInstance().chat.off('channel:added', resolveFunction);
                        return resolve(data);
                    }
                };

                BandyerSDK.getInstance().chat.on('channel:added', resolveFunction);
                await BandyerSDK.getInstance().chat.addChannel(user, options);
                dispatch(expandWidget());
                dispatch(hideWidget(false));
            } catch (e) {
                return reject(e);
            } finally {
                dispatch(chatIsLoading(false));
            }
        });


        /* return BandyerSDK.getInstance().chat.addChannel(user, options)
            .then((channel) => {
                dispatch(chatIsLoading(false));
                dispatch(expandWidget());
                dispatch(hideWidget(false));
                return Promise.resolve(channel);
            })
            .catch((err) => {
                dispatch(chatIsLoading(false));
                throw new ExposeError(err);
            }); */
    };
}

function removeChannel(user) {
    return (dispatch) => {
        dispatch(chatIsLoading(true));
        if (!BandyerSDK.getInstance().chat) {
            throw new ExposeError({ code: 'remove_chat_error', message: 'Chat not initialized' });
        }
        return BandyerSDK.getInstance().chat.removeChannel(user)
            .then((uniqueNameRemoved) => {
                Logger.debug('Channel removed : ', uniqueNameRemoved);
                dispatch(chatIsLoading(false));
                return Promise.resolve();
            })
            .catch((err) => {
                dispatch(chatIsLoading(false));
                throw new ExposeError(err);
            });
    };
}

function sendIsTypingEvent(uniqueName) {
    return () => BandyerSDK.getInstance().chat.isTyping(uniqueName);
}

/* Fn that return to the channels view and bring the selected channel to none */
function resetToChannelsView() {
    return (dispatch) => {
        dispatch(changeView(CHANNELS));
        dispatch(selectCurrentChannel(''));
    };
}

/* Fn that is called on call event, allow to return to the current conversation or back to channels if
  there isn't a selected one
  */

function resetToChannelView() {
    return (dispatch) => {
        const state = store.getState();
        const view = state.behavior.get('view');
        const { enabled: isFeedbackEnabled } = state.behavior.get('feedbackInfo');
        const isCalling = state.behavior.get('call').size;
        if (view === ERROR) {
            return;
        }
        const selectedChannel = state.behavior.get('selectedChannel');
        if ((view === 'FEEDBACK' || view === 'CALL' || view === 'CALL_WINDOW') && isCalling && isFeedbackEnabled) {
            dispatch(changeView(FEEDBACK));
        } else {
            if (state.behavior.get('wasClose')) {
                dispatch(closeWidget());
            }
            if (state.behavior.get('wasHide')) {
                dispatch(hideWidget(true));
            }
            if (selectedChannel === '') {
                dispatch(changeView(CHANNELS));
            } else {
                dispatch(changeView(CONVERSATION));
            }
        }
    };
}

function resetCurrentChannel() {
    BandyerSDK.getInstance().chat.resetCurrentChannel();
}

/** ***************************************************************************
 ************ LIFECYCLE OPERATIONS ********************************************
 ************************************************************************** */

const initialize = function initialize(config) {
    return (dispatch) => {
        dispatch(chatIsLoading(true));
        try {
            if (!isBrowserSupported(BrowserUtil.browserName(), BrowserUtil.browserVersion())) {
                throw new ExposeError({ code: 'browser_not_supported', message: 'Please check the browser compatibility' });
            }
            if (config.hidden) {
                dispatch(hideWidget(true));
            }
            if (config.recordingType) {
                dispatch(setRecordingValue(config.recordingType));
            }
            if (config.isAdmin) {
                dispatch(setAdmin(true));
            }
            if (config.layout) {
                styleOperations.handleConfigLayout(dispatch, config.layout);
            }
            if (config.feedback) {
                dispatch(setFeedbackEnabled(config.feedback));
            }
            dispatch(setLanguage(validateLanguageConfig(config.language)));


            if (config.tools && config.tools.chat) {
                dispatch(setChatInitialized(config.tools.chat));
            }

            if (config.tools) {
                dispatch(setTools(validateToolsConfig(config.tools)));
            }

            dispatch(setConnectionInfo(config.region, config.environment, config.appId));
            new BandyerSDK({
                region: config.region,
                environment: config.environment,
                appId: config.appId,
                chat: !!(config.tools && config.tools.chat)
            });

            const client = new Client(config.region, config.environment, config.appId);

            UserDetailsService.initialize(config.userDetailsProvider, config.userDetailsFormatter);
            VirtualBackgroundService.init();

            if (config.virtualBackground) {
                const virtualBackground = { enable: true, type: config.virtualBackground };
                dispatch(setVirtualBackgroundConfig(virtualBackground));
            }
            dispatch(setWidgetCallType(validateCallTypeConfig(config.callType)));
            dispatch(setWidgetMode(validateModeConfig(config.mode)));
            return client;
        } catch (e) {
            dispatch(chatInitHasError(true));
            dispatch(chatIsLoading(false));
            Logger.warn('[initialize] - error', e);
            throw new ExposeError(e);
        }
    };
};

const connect = function connect(userId, accessToken) {
    return async(dispatch) => {
        try {
            const localUser = await BandyerSDK.getInstance().connect(userId, accessToken);
            const chatBandyerDiv = document.createElement('div');
            chatBandyerDiv.id = Style[BANDYER_CHAT_CONTAINER];
            document.body.appendChild(chatBandyerDiv);
            ReactDOM.render(
                <Provider store={store}>
                    <BandyerChatWidget connectionState />
                </Provider>,
                document.getElementById(Style[BANDYER_CHAT_CONTAINER])
            );

            dispatch(changeView(CHANNELS));
            dispatch(chatIsLoading(false));
            dispatch(setLocalUserInfo(localUser));
            dispatch(setAllowCamera(localUser.allowCamera));
            dispatch(setWidgetReady(true));
        } catch (e) {
            Logger.error('[connect] - error', e);
            throw new ExposeError(e);
        }
    };
};

const updateAccessToken = function updateAccessToken(accessToken) {
    return async() => {
        try {
            return BandyerSDK.getInstance().updateAccessToken(accessToken);
        } catch (error) {
            Logger.warn('[updateAccessToken] - error', error);
            throw new ExposeError(error);
        }
    };
};

const shutdown = function shutdown() {
    return async(dispatch) => {
        try {
            await BandyerSDK.getInstance().disconnect();
            BandyerChat.destroy();
            dispatch(setWidgetReady(false));
        } catch (e) {
            Logger.warn('[chatLogout] - logout error', e);
            dispatch(setWidgetReady(false));
        }
    };
};

const destroy = function destroy() {
    return async(dispatch) => {
        dispatch(logout());
    };
};


/** ******** CALL OPERATIONS ******************** */

export default {
    addUserMessage,
    sendMessage,
    selectChannel,
    toggleFullScreen,
    toggleChat,
    logout,
    initialize,
    changeView,
    updateUnreadMessagesForChannel,
    shutdown,
    setWidgetReady,
    addChannel,
    removeChannel,
    sendIsTypingEvent,
    resetCurrentChannel,
    resetToChannelsView,
    resetToChannelView,
    addResponseMessage,
    toggleInputDisabled,
    fetchedSubscribedChannels,
    removeSubscribedChannel,
    channelIsTyping,
    updateChannelLastMessage,
    expandWidget,
    selectCurrentChannel,
    closeWidget,
    setExtensionUrl,
    updateLastMessageReceived,
    updateLastMessageSent,
    setUnreadMessagesForChannel,
    hideWidget,
    fetchedSubscribedChannelsNoResults,
    getUserStatus,
    setWidgetCallType,
    setAllowCamera,
    setChannel,
    getUsersStatusList,
    setLanguage,
    setUserStatusInChannel,
    fetchMessages,
    removeChat,
    setHaveChat,
    setVirtualBackgroundConfig,
    setFeedbackInfo,
    setFeedbackEnabled,
    updateAccessToken,
    connect,
    setChatInitialized,
    chatIsLoading,
    setLocalUserInfo,
    updateChannelTextArea,
    destroy,
    emptyMessages,
    emptyChannels,
    updateLastReadUserMessage
};
