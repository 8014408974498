import { Client } from '@bandyer/chat-sdk-web';
import store from '../../store/store';
import ChannelsManager from './manager/channelsManager';
import {
    emptyChannels,
    setHaveChat,
    updateChannelLastMessage, updateChannelLastReadUserMessage,
    updateChatSocketState
} from '../../store/actions/dispatcher';
import { buildChannelUniqueName } from '../../helpers/utils';
import Logger from '../../logger';

const events = require('events');

let instance = null;
class BandyerChat extends events {
    constructor(bandyerCommunicationCenter, region, environment) {
        if (instance) {
            return instance;
        }
        super();
        this._region = region;
        this._environment = environment;
        this._accessToken = null;
        this._tokenExpiresAt = null;
        this._currentuser = null;
        this._bandyerCommunicationCenter = bandyerCommunicationCenter;
        this._dateCreated = new Date();
        instance = this;
        this._loadingTimeout = null;
        this._requestTokenPendingPromise = null;
    }

    static getInstance() {
        return instance;
    }

    static destroy() {
        ChannelsManager.destroy();
        instance = null;
    }


    async connect(userId, accessToken, expiresAt) {
        this._currentuser = userId;
        this._tokenExpiresAt = expiresAt;
        this._accessToken = accessToken;
        this._channelsManager = new ChannelsManager();
        try {
            const options = {
                client: 'widget',
                subscriptionType: 'auto' // can be auto or manual
            };
            this._bandyerChatSdk = new Client(this._region, this._environment, userId, accessToken, options);
            if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'demo') {
                window.bandyerChatSdk = this;
                this._bandyerChatSdk.logLevel = 'info';
            } else {
                this._bandyerChatSdk.logLevel = 'warn';
            }
            this._registerEvents();
            await this._bandyerChatSdk.initialize(userId, accessToken);
            updateChatSocketState('CONNECTED');
            this._loadingTimeout = setTimeout(() => {
                setHaveChat(false);
            }, 1000);
            setHaveChat(true);
        } catch (e) {
            Logger.error('Unable to connect Bandyer chat sdk, error: ', e);
        }
    }

    async disconnect() {
        ChannelsManager.destroy();
        return this._bandyerChatSdk.logout();
    }

    _registerEvents() {
        this._bandyerChatSdk.on('channelAdded', async(data) => {
            const channel = await this._channelsManager.handleChannelAdded(data.channel);
            this.emit('channel:added', channel); // for compatibility
        });
        this._bandyerChatSdk.on('channelRemoved', (data) => {
            const { channelId, participants } = this._channelsManager.handleChannelRemoved(data.channelId);
            this.emit('channel:removed', { channelId, participants });
        });
        this._bandyerChatSdk.on('messageAdded', (data) => {
            const { channelId, message } = data;
            const messageAdded = this._channelsManager.handleMessageAdded(channelId, message);
            this.emit('messageAdded', messageAdded);
        });
        this._bandyerChatSdk.on('channelUpdated:userLastRead', (data) => {
            const { channelId, lastReadUserMessage } = data;
            const channel = this._channelsManager.channelsByChannelId.get(channelId);
            channel.lastReadUserMessage = lastReadUserMessage;
            const { participants, uniqueName } = channel.toObj();
            updateChannelLastReadUserMessage(uniqueName, lastReadUserMessage);
            const messageId = lastReadUserMessage.get(participants.find(user => user.userAlias !== this._currentuser).userAlias);
            const message = this._channelsManager.channelsByChannelId.get(channelId).messages.get(messageId).toObj();
            const payload = {
                id: message.messageId,
                chat: uniqueName,
                participants: participants.map(p => p.userAlias),
                text: message.message,
                timestamp: message.timestamp,
                sender: message.who
            };
            this.emit('channel_updated:user_last_read', payload);
        });
        this._bandyerChatSdk.on('isTyping', (data) => {
            const { channelId, userId } = data;
            this._channelsManager.handleTyping(channelId, userId);
            if (this._channelsManager.channelsByChannelId.has(channelId)) {
                const { participants, uniqueName } = this._channelsManager.channelsByChannelId.get(channelId).toObj();
                this.emit('is_typing', { userAlias: userId, chat: uniqueName, participants: participants.map(p => p.userAlias) });
            }
        });
        this._bandyerChatSdk.on('connect', () => { updateChatSocketState('CONNECTED'); });
        this._bandyerChatSdk.on('reconnecting', () => { updateChatSocketState('RECONNECTING'); });
        this._bandyerChatSdk.on('disconnected', () => { updateChatSocketState('DISCONNECTED'); });

        this._bandyerChatSdk.on('connection_error', async() => {
            updateChatSocketState('DISCONNECTED');
        });
        this._bandyerChatSdk.on('tokenAboutToExpire', async() => {
            await this._renewToken();
        });
        this._bandyerChatSdk.on('tokenExpired', async() => {
            await this._renewToken();
        });
    }

    async _renewToken() {
        if (!this._requestTokenPendingPromise) {
            this._requestTokenPendingPromise = new Promise(async(resolve) => {
                const { accessToken, expiresAt } = await this._bandyerCommunicationCenter.getChatToken();
                this._accessToken = accessToken;
                this._tokenExpiresAt = expiresAt;
                await this._bandyerChatSdk.updateToken(this._accessToken);
                this._requestTokenPendingPromise = null;
                resolve();
            });
            await Promise.race([setTimeout(() => { this._requestTokenPendingPromise = null; }, 2500), this._requestTokenPendingPromise]);
        }
    }

    addChannel(user) {
        return this._bandyerChatSdk.createChannelOto(user);
    }

    removeChannel(user) {
        const participants = [user, this._currentuser];
        const uniqueName = buildChannelUniqueName(participants);
        return this._channelsManager.removeChannel(uniqueName);
    }

    selectCurrentChannel(uniqueName, participants) {
        return this._channelsManager.selectCurrentChannel(uniqueName, participants);
    }

    fetchMessage(uniqueName, anchor) {
        return this._channelsManager.fetchMessage(uniqueName, anchor);
    }

    sendMessage(uniqueName, message, options = {}) {
        return this._channelsManager.sendMessage(uniqueName, message, options);
    }

    isTyping(uniqueName) {
        this._channelsManager.isTyping(uniqueName);
    }

    resetCurrentChannel() {
        this._channelsManager.selectedChannel = null;
    }

    getChannelByUniqueName(uniqueName) {
        return this._channelsManager.channelsByUniqueName.get(uniqueName);
    }

    get localUserAlias() {
        return this._currentuser;
    }

    get status() {
        return store.socketState.get('chat_socket_state');
    }
}
export default BandyerChat;
