import { BandyerEnum } from 'bandyersdkcommon';
import Logger from '../../logger';
import { CALL_WINDOW_HANGUP_ACTION } from '../../constants';
import * as dispatcher from '../../store/actions/dispatcher';

const events = require('events');

export default class CallWindow extends events {
    constructor(bandyerCommunicationCenter, region, environment, language) {
        super();
        this._L = Logger.scope('Services - Call - Window');
        this._windowInstance = null;
        this._language = language || 'en';
        this._windowTitle = 'BandyerCallWidget';
        this._windowOptions = 'status=1,resizable=1';
        this._bandyerCommunicationCenter = bandyerCommunicationCenter;
        this._callReadyInterval = null;
        this._callReadyIntervalTime = 1000;
        this._windowCallOpenedIntervalTime = 500;
        this._environment = environment;
        this._region = region;
        this._onGoingCall = null;
        window.addEventListener('message', (event) => {
            if (event.data) {
                switch (event.data.action) {
                    case 'callserver:waiting_room_ready':
                        // clearInterval(this._windowCallOpenedInterval);
                        this._callReadyInterval = setInterval(
                            this._handleCallServerReady.bind(this),
                            this._callReadyIntervalTime
                        );
                        break;
                    case 'callserver:upgrade_video':
                        if (this._bandyerCommunicationCenter.onGoingCall) {
                            this._bandyerCommunicationCenter.onGoingCall.upgradeVideo();
                        }
                        break;
                    default:
                }
            }
        });
    }

    _handleCallServerReady() {
        // todo Qui il dialog è aperto e ha già aperto la pagina di call.
        if (this._bandyerCommunicationCenter.onGoingCall) {
            if (this._bandyerCommunicationCenter.onGoingCall.callDirection === BandyerEnum.CallDirection.OUTGOING) {
                // chiamata create
                this._L.debug(
                    '_handleCallServerReady - outgoing',
                    this._bandyerCommunicationCenter.onGoingCall.callStatus
                );
                if (this._bandyerCommunicationCenter.onGoingCall.callStatus === BandyerEnum.CallStatus.CONNECTING) {
                    this.sendMessage({
                        action: 'callserver:jwt_from_widget',
                        jwt: this._bandyerCommunicationCenter.onGoingCall.callHost.jwt,
                        tools: {
                            ...dispatcher.getCallTools(),
                            virtual_background: dispatcher.getVirtualBackgroundSetting()
                        },
                        usersDetails: dispatcher.getUsersDetailsCallPage(
                            this._bandyerCommunicationCenter.onGoingCall.callParticipants
                        )
                    });
                    dispatcher.changeViewToCallWindow();
                    this._onGoingCall = this._bandyerCommunicationCenter.onGoingCall;
                    this._onGoingCall.setCallStatus('roomCalling');
                    this.emit('call_started_window', { call: this._bandyerCommunicationCenter.onGoingCall });
                    clearInterval(this._callReadyInterval);
                    this._callReadyInterval = null;
                }
                /*  if (!this._windowInstance || (this._windowInstance && this._windowInstance.closed)) {
                     this._L.debug('_handleCallServerReady - closed');
                     clearInterval(this._callReadyInterval);
                     this._callReadyInterval = null;
                     // The call never started, here if the call is hangUpped from the widget
                     if (this._bandyerCommunicationCenter.onGoingCall.callStatus !== BandyerEnum.CallStatus.ENDED) {
                         this.emit(CALL_WINDOW_HANGUP_ACTION, { call: this._bandyerCommunicationCenter.onGoingCall });
                     }
                } */
                /* if (this._bandyerCommunicationCenter.onGoingCall.callStatus === BandyerEnum.CallStatus.ENDED) {
                    clearInterval(this._callReadyInterval);
                    this._callReadyInterval = null;
                } */
                // todo in caso di decline pulire il timeout
            } else {
                // chiamata ricevuta
                this.sendMessage({
                    action: 'callserver:jwt_from_widget',
                    jwt: this._bandyerCommunicationCenter.onGoingCall.callHost.jwt,
                    tools: {
                        ...dispatcher.getCallTools(),
                        virtual_background: dispatcher.getVirtualBackgroundSetting()
                    },
                    usersDetails: dispatcher.getUsersDetailsCallPage(
                        this._bandyerCommunicationCenter.onGoingCall.callParticipants
                    )
                });
                dispatcher.changeViewToCallWindow();
                this._onGoingCall = this._bandyerCommunicationCenter.onGoingCall;
                this._onGoingCall.setCallStatus('roomCalling');
                this.emit('call_started_window', { call: this._bandyerCommunicationCenter.onGoingCall });
                clearInterval(this._callReadyInterval);
                this._callReadyInterval = null;
            }
        }
    }

    cleanCallInterval() {
        if (this._callReadyInterval) {
            clearInterval(this._callReadyInterval);
            this._callReadyInterval = null;
        }
    }

    startCallWindow() {
        const { newUI } = this._bandyerCommunicationCenter.company;
        // todo da modificare con url custom
        this._L.debug('[startCallWindow]');
        if (this._environment === 'develop') {
            this._windowInstance = window.open(
                `https://develop.bandyer.com/${this._region}/call/call-from-widget?l=${this._language}&new_ui=${newUI}`,
                this._windowTitle,
                this._windowOptions
            );
        } else if (this._environment === 'development2') {
            this._windowInstance = window.open(
                'https://develop2.bandyer.com/connect/call/call-from-widget/true',
                this._windowTitle,
                this._windowOptions
            );
        } else {
            this._L.debug('[startCallWindow] -', this._bandyerCommunicationCenter.company);
            this._windowInstance = window.open(`https://${this._bandyerCommunicationCenter.company.urlRootPath}.bandyer.com/${this._region}/call/call-from-widget?l=${this._language}&new_ui=${newUI}`,
                this._windowTitle,
                this._windowOptions);
        }
        this._windowCallOpenedInterval = setInterval(() => {
            this._L.debug('[startCallWindow] #startCallWindow', this._windowCallOpenedInterval);
            if (!this._windowInstance || (this._windowInstance && this._windowInstance.closed)) {
                clearInterval(this._windowCallOpenedInterval);
                // send HANG_UP to commCenter, because the call is not in connected state.
                // This because for the commCenter the call has stated with autoConnect = false
                this.emit(CALL_WINDOW_HANGUP_ACTION, { call: this._onGoingCall });
                this._onGoingCall = null;
            }
        }, this._windowCallOpenedIntervalTime);
    }

    answerCallWindow() {
        const { newUI } = this._bandyerCommunicationCenter.company;
        if (this._environment === 'develop') {
            this._windowInstance = window.open(
                `https://develop.bandyer.com/${this._region}/call/call-from-widget?l=${this._language}&new_ui=${newUI}`,
                this._windowTitle,
                this._windowOptions
            );
        } else if (this._environment === 'development2') {
            this._windowInstance = window.open(
                'https://develop2.bandyer.com/connect/call/call-from-widget/true',
                this._windowTitle,
                this._windowOptions
            );
        } else {
            this._L.debug('[startCallWindow] -', this._bandyerCommunicationCenter.company);
            this._windowInstance = window.open(
                `https://${this._bandyerCommunicationCenter.company.urlRootPath}.bandyer.com/${this._region}/call/call-from-widget?l=${this._language}&new_ui=${newUI}`,
                this._windowTitle,
                this._windowOptions
            );
        }
        this._windowCallOpenedInterval = setInterval(() => {
            this._L.debug('[answerCallWindow] #startCallWindow', this._windowCallOpenedInterval);
            if (!this._windowInstance || (this._windowInstance && this._windowInstance.closed)) {
                clearInterval(this._windowCallOpenedInterval);
                // send HANG_UP to commCenter, because the call is not in connected state.
                // This because for the commCenter the call has stated with autoConnect = false
                this.emit(CALL_WINDOW_HANGUP_ACTION, { call: this._onGoingCall });
                this._onGoingCall = null;
            }
        }, this._windowCallOpenedIntervalTime);
    }

    focusWindow() {
        if (this._windowInstance) {
            this._windowInstance.focus();
        }
    }

    closeWindow() {
        if (this._windowInstance) {
            this._windowInstance.close();
            this._windowInstance = null;
        }
    }

    sendMessage(message) {
        this._L.debug('[sendMessage]', message);
        if (this._windowInstance) {
            this._windowInstance.postMessage(message, '*');
        }
    }
}
