import { Map, List } from 'immutable';
import actionTypes from './types';
import { PUBLISHER_WEBCAM } from '../../../../../../../constants';

const initialState = Map({
    mainVideo: PUBLISHER_WEBCAM,
    audioMuted: false,
    videoMuted: false,
    webcamPublished: false,
    screenPublished: false,
    extensionUrl: '',
    extensionAlert: false,
    videoHasAudio: false,
    videoHasVideo: false,
    mediaDevices: [],
    selectedAudioDevice: 'none',
    selectedVideoDevice: 'none',
    cameraPermissionDenied: false,
    microphonePermissionDenied: false,
    mediaStream: null,
    streamEnded: false,
    streamFailed: false,
    recordingError: false,
    reconnectingCall: false,
    error: null,
    verification: null,
    remoteMuted: false,
    filesToDownload: Map([]),
    filesUploaded: Map([]),
    recordingInfo: { recordingInProgress: false, recording: 'none' },
    isAdmin: false,
    virtualBackgrounds: [],
    showRequestPlayPermission: null,
    failedMediaElements: [],
    showMuteByAdmin: false
});

export default function reducer(state = initialState, action) {
    switch (action.type) {
        // devo ricevere i dati dalla action
        case actionTypes.PUBLISH_VIDEO: {
            return state.update('webcamPublished', () => action.publish);
        }
        case actionTypes.PUBLISH_SCREEN: {
            return state.update('screenPublished', () => action.publish);
        }
        case actionTypes.CHANGE_MAIN_VIDEO: {
            return state.update('mainVideo', () => action.mainVideo);
        }
        case actionTypes.CHANGE_MUTE_VIDEO: {
            return state.update('videoMuted', () => action.muted);
        }
        case actionTypes.CHANGE_MUTE_AUDIO: {
            return state.update('audioMuted', () => action.muted);
        }
        case actionTypes.SET_EXTENSION_URL: {
            return state.update('extensionUrl', () => action.extensionUrl);
        }
        case actionTypes.SHOW_EXTENSION_ALERT: {
            return state.update('extensionAlert', () => action.show);
        }
        case actionTypes.VIDEO_HAS_TRACK_AUDIO: {
            return state.update('videoHasAudio', () => action.hasAudioTrack);
        }
        case actionTypes.VIDEO_HAS_TRACK_VIDEO: {
            return state.update('videoHasVideo', () => action.hasVideoTrack);
        }
        case actionTypes.MEDIADEVICES: {
            return state.update('mediaDevices', () => action.mediaDevices);
        }
        case actionTypes.SELECT_AUDIO_DEVICE: {
            return state.update('selectedAudioDevice', () => action.audioDevice);
        }
        case actionTypes.SELECT_VIDEO_DEVICE: {
            return state.update('selectedVideoDevice', () => action.videoDevice);
        }
        case actionTypes.SET_CAMERA_PERMISSION_DENIED: {
            return state.update('cameraPermissionDenied', () => action.permission);
        }
        case actionTypes.SET_MICROPHONE_PERMISSION_DENIED: {
            return state.update('microphonePermissionDenied', () => action.permission);
        }
        case actionTypes.SET_MEDIA_STREAM: {
            return state.update('mediaStream', () => action.mediaStream);
        }
        case actionTypes.SET_STREAM_ENDED: {
            return state.update('streamEnded', () => action.streamEnded);
        }
        case actionTypes.SET_STREAM_FAILED: {
            return state.update('streamFailed', () => action.streamFailed);
        }

        case actionTypes.SET_RECORDING_ERROR: {
            return state.update('recordingError', () => action.recordingError);
        }
        case actionTypes.SET_RECONNECTING_CALL: {
            return state.update('reconnectingCall', () => action.reconnecting);
        }
        case actionTypes.SET_ERROR: {
            return state.update('error', () => action.error);
        }
        case actionTypes.SET_VERIFICATION: {
            return state.update('verification', () => action.verification);
        }
        case actionTypes.SET_REMOTE_MUTED: {
            return state.update('remoteMuted', () => action.muted);
        }
        case actionTypes.SET_FILE_TO_DOWNLOAD: {
            let filesToDownload = state.get('filesToDownload');
            filesToDownload = filesToDownload.set(action.newFile.get('name'), action.newFile);
            return state.update('filesToDownload', () => filesToDownload);
        }
        case actionTypes.SET_FILE_UPLOADED: {
            let filesUploaded = state.get('filesUploaded');
            filesUploaded = filesUploaded.set(action.newFile.get('name'), action.newFile);
            return state.update('filesUploaded', () => filesUploaded);
        }
        case actionTypes.SET_FILES_TO_DOWNLOAD: {
            return state.update('filesToDownload', () => action.filesToDownload);
        }
        case actionTypes.SET_FILES_TO_UPLOAD: {
            return state.update('filesUploaded', () => action.filesUploaded);
        }
        case actionTypes.REMOVE_FILE_UPLOADED: {
            let filesUploaded = state.get('filesUploaded');
            filesUploaded = filesUploaded.remove(action.fileName);
            return state.update('filesUploaded', () => filesUploaded);
        }
        case actionTypes.UPDATE_RECORDING_INFO: {
            return state.update('recordingInfo', () => action.recordingInfo);
        }
        case actionTypes.UPDATE_RECORDING_IN_PROGRESS: {
            const recordingInfo = state.get('recordingInfo');
            const newRecordingInfo = { recordingInProgress: action.recordingInProgress, recording: recordingInfo.recording }; // new object to trigger redux state update
            return state.update('recordingInfo', () => newRecordingInfo);
        }
        case actionTypes.SET_IS_ADMIN: {
            return state.update('isAdmin', () => action.isAdmin);
        }
        case actionTypes.SET_VIRTUAL_BACKGROUNDS: {
            return state.update('virtualBackgrounds', () => action.virtualBackgrounds);
        }
        case actionTypes.SHOW_REQUEST_PLAY_PERMISSION: {
            return state.update('showRequestPlayPermission', () => action.show);
        }
        case actionTypes.SET_FAILED_MEDIA_ELEMENT: {
            const failedMediaElements = state.get('failedMediaElements');
            failedMediaElements.push(action.HTMlMediaElement);
            return state.update('failedMediaElements', () => failedMediaElements);
        }
        case actionTypes.SHOW_MUTE_BY_ADMIN: {
            return state.update('showMuteByAdmin', () => action.show);
        }
        default:
            return state;
    }
}
